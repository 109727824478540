
import router from '@/router';
import {
  IonButtons,
  
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonToggle
  
  } from '@ionic/vue';
  
import common from  "../js/common"
 import Download from '@/components/ScaricamentoContenuti.vue';

//import { defineComponent } from 'vue';
export default ({
  name: "langSwitch",
  props: {
    title: { type: String, default: 'Default Title' }
  },
  components: {
    IonButtons,
   
    IonContent,
    IonHeader,
    IonToolbar,
    IonToggle
    
  },
  data(){
    return{
     currPerc:null,
      currLang:this.currentLang,
      attivaSupporto:0

    }
  },
  setup(){
   
    return{
      
    }
  },
  computed:{

    supportoVisivo(){
      const pubblication=JSON.parse(localStorage.getItem('pubblication'));
      if(pubblication.hasOwnProperty("supporto_video")){
        return pubblication.supporto_video

      }else{
        return null
      }
      

    },
    getAttivaSuppoto(){
      const supporto=JSON.parse(localStorage.getItem('attivaSupporto'));
      if(supporto){
        if(supporto==true){
          return true
        }else{
          return false
        }

      }else{
        return false
      }
    },

    
    

  
    
  },
 
  mounted(){
  
   this.attivaSupporto=this.getAttivaSuppoto;
  
  
    
  },

  methods:{

    notice(){
      console.log("fire notice")
      localStorage.setItem('attivaSupporto', this.attivaSupporto)
    },

  
    back(){
      const ionNav = document.querySelector('ion-nav') ;
            ionNav.pop();    
    },
   

  }
})
