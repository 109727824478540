<template>
  <ion-page>
  <ion-header class="ion-no-border">
    <ion-toolbar class="toolbar" >
      <ion-title > </ion-title>
      <ion-buttons slot="start" >
        <ion-button v-on:click="aletrtexit()" class="back-button"><ion-icon size="medium" name="arrow-back"></ion-icon> {{$t('schede.back')}}</ion-button>
      </ion-buttons>

      <!--ion-buttons slot="end" >
        <ion-button v-if="visited.length>0" color="secondary" :key="visited.length"  @click="openviste()" class="collection-button">
          <ion-icon   name="file-tray-full-outline" class="history-icon map-icons"></ion-icon*>
           <svg-icon></svg-icon>
        </ion-button>
        </ion-buttons-->
    </ion-toolbar> 
  </ion-header>

   
    <ion-content :fullscreen="true">
      <div class="vertical-center ">
        <div :class="isloaded==false ?'blur':'' ">
          
          <div id="map"   style="height: 95vh; width: 100vw;" ></div>
         
          <div id="punto"></div>

          <div class="utils-container">
            <ion-button class="reload-button" @click="updateMap"><img class="icon-button" src="assets/background/reload.png"></ion-button>
            <ion-button  v-if="visited.length>0"  :key="visited.length"  class="raccolta-button" @click="openviste()"> <svg-icon></svg-icon></ion-button>
           
            <div class="info-coord"> open{{ open }}
              lat: {{ userCoord.latitude }} -
              long {{ userCoord.longitude }} ---
              distanza {{ distance }} m
           </div>
          </div>

        </div>
      </div>
    </ion-content>
    
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonButton,
  IonHeader,
  alertController,
  modalController
  //IonModal
  
} from "@ionic/vue";


import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import Amplitude from "./Amplitude.vue";
import SvgIcon from "@/components/svgPack.vue";


export default {
  name: "gps",
  props: {
    title: { type: String, default: 'Default Title' }
  },
  data() {
    return {
      userCoord: { latitude: 0, longitude: 0 },
      distance: null,
      cityCoord: { latitude:  45.466479544159796, longitude:9.192086626765356 } ,
      map: null,
      mapheight:"",
      mapwidth:"",
      bounds:"",
      imageBounds:"",
      mapImage:"",
     /* bergamo
     mapwidth:2645,
      mapheight:3000,
      bounds: [[0,0], [3000,2645]],
      imageBounds : [[0,0], [3000,2645]],*/

      /*milano
      mapwidth:1248,
      mapheight:987,
    bounds: [[0,0], [987,1248]],
      imageBounds : [[0,0], [987,1248]],*/

      url: '/assets/background/map/mappa.png',
      items: [],
      markers:[],
      open:false,
      isloaded:false,
      range:0,
      visited:[],
      introOpen:false,
      openingScheda:false
    };
  },
  
  components: {
   IonContent,
    IonPage,
    IonHeader,
    //IonModal
    SvgIcon
  
  },
  computed:{
    schede(){
      const data=localStorage.getItem("dataMostra")
     
      const schede= JSON.parse(data);
      return schede
    },
    lang() {
      const lang= localStorage.getItem("lang")
      if (lang) {
        return lang;
      } else {
        return this.$i18n.locale;
      }
    },
    currPerc() {
     
      const percs= localStorage.getItem("percSel")
      const perc= JSON.parse(localStorage.getItem("percorsi"))
      const currPerc=perc.find(p=>p.percorso==percs)
      return currPerc
     
   },

    infomap() {
     
      const percs= localStorage.getItem("percSel")
      const perc= JSON.parse(localStorage.getItem("percorsi"))
      const currPerc=perc.find(p=>p.percorso==percs)
  
      if (currPerc) {
        return currPerc.infomappa
       
      } else {
        return null;
      }
    },
    mapImg(){
      const linguaperc = this.currPerc.lingue.find(l=>l.lang==this.lang)
        if(linguaperc){
          return linguaperc.imgMappa
        }else{
          return null;
        }

    },

    visitedSchede:{
      get() {
        let visitedTag=localStorage.getItem("schede_viste_onmap");  
        if(visitedTag){
            visitedTag=visitedTag.split(',');
            console.log("visitedtag: ",visitedTag)
            return visitedTag;
        }else{
            return [];
        } 
     },
     set(info){
      localStorage.setItem(info.variabile, info.ntag);
     }
    }

  },
  activated(){
    console.log("Activated Mappa")

  },
 
  beforeMount(){
    try{
      console.log("executeJava");
      AndroidObject.executeJavaCodeNotificationMessage(true); 
    }catch(e){
      console.log("catch ",e);
      if(typeof AndroidObject=="undefined"){
      }else{
        console.log("catch ",e);
      }
    }


    navigator.geolocation.getCurrentPosition((position)=>{  
      this.userCoord.latitude= position.coords.latitude,
      this.userCoord.longitude= position.coords.longitude

    })
    console.log("MAPIMAGE "+this.mapImage)
    console.log("infomappa ",this.infomap)
    this.range=this.conf.range;
    this.lat1=this.infomap!=null? Number(this.infomap.coordTL.latitude): this.conf.lat1;
    this.lon1=this.infomap!=null?Number(this.infomap.coordTL.longitude):this.conf.lon1;
    this.lat2=this.infomap!=null?Number(this.infomap.coordBR.latitude):this.conf.lat2;
    this.lon2=this.infomap!=null?Number(this.infomap.coordBR.longitude):this.conf.lon2;
    this.mapheight=this.infomap!=null?Number(this.infomap.hMappa):this.conf.altezzaMappa;
    this.mapwidth=this.infomap!=null?Number(this.infomap.WMappa):this.conf.larghezzaMappa;
    this.bounds=[[0,0], [this.mapheight,this.mapwidth]],
    this.imageBounds=[[0,0],  [this.mapheight,this.mapwidth]],
    console.log("configlatlan",typeof this.lat1,this.lat2, this.lon1, this.lon2);
    this.visited=this.visitedSchede;
   const savedCoord=JSON.parse(localStorage.getItem('userCoord'));
    let mypoint;
    console.log("usercoord  ", savedCoord)
    if(savedCoord){
      this.userCoord=savedCoord
       mypoint={id: 'me', description: 'ME', latLng: this.coordtopixelPunti( this.userCoord.latitude,this.userCoord.longitude), status: 3}
    }else{
       mypoint={id: 'me', description: 'ME', latLng: this.coordtopixelPunti(this.lat1,this.lon1), status: 3}
    }
    
    this.items.push(mypoint);
    console.log("mypoint ",mypoint)
    
    this.schede.forEach(scheda=>{
      const cont= scheda.content.find(x => x.lang == this.lang);
    
      if(scheda.coord!=null){
        const status= !this.visited.includes(scheda.tag)?1:2; 
        const point={id:scheda.tag, description: cont.titolo, latLng: this.coordtopixelPunti(scheda.coord.latitude, scheda.coord.longitude), status: status, img:scheda.img};
        this.items.push(point);
      }
    })
        
   
  },
  async mounted(){
    this.getLocation();
    const mapToget=this.mapImg!=null?this.mapImg:this.infomap.imgMappa
    this.mapImage= await this.getmapImg(mapToget);
    this.drawMap();

    if(localStorage.getItem("alertmappaletto")!=1){
      //this.aletrtMap();
      this.introOpen=true;
      this.introModal();
    }

    setTimeout(() => {
      this.updateMap();
      this.isloaded=true;
      
    }, 450);
  
   console.log("items",this.items)

   window.document.addEventListener("visibilitychange", ()=> { 
    console.log("BG GEO?")
  
   })
             
  },
  async beforeUnmount() {
    if (this.map) {
      this.map.remove();
      this.mapImage=null
    }
    this.open==false
   
    this.clearwatcher();
    console.log("Unmounting map");
    const top = await modalController.getTop();
    if (top) top.dismiss();
  },

  methods: {
   async getmapImg(name){
    if (name != null) {
      return new Promise((resolve, reject) => {
       this.request = indexedDB.open('mediaStore', global.dbVersion);
        this.request.onsuccess = event => {
             this.db = event.target.result;
             const transaction = this.db.transaction("general", "readonly");
          const objstore = transaction.objectStore("general");
          const getRequest = objstore.get(name);

          getRequest.onsuccess = event => {
            console.log("GET RESULT ", event.target.result)
            const testget = event.target.result;      
            if (testget) {
            const img= URL.createObjectURL(testget.blob);
                
            // this.imgSrc='data:'+testget.blob.type+';base64,'+btoa(testget.data);
              resolve(img);
              this.db.close();
            } else {
              console.log('testget dont exixst error');
                this.fetchImg(name);
            }
      
          };
        }
        this.request.onerror= event=>{
          reject('Error getting image');
          this.fetchImg(name);
        }
      })
      }else{
        //return this.url;
      }
    },
   
   
  
    fetchImg(name){
       console.log("TRYIN FETCH")
        const mediaRequest = fetch(this.$store.getters.baseUrl+"/upload/"+name).then(response => response.blob()).catch(err => {console.error(err); console.log("sono in errore")});
    
        mediaRequest.then(blob => {
          const fileblob=blob;
           this.mapImage=  URL.createObjectURL(fileblob)
         
        
          const objectStore =this.db.transaction("general",'readwrite').objectStore("general");
            console.log('blobb ',fileblob)
            const objectStoreRequest = objectStore.add({name: name, blob: fileblob});
            objectStoreRequest.onsuccess = event=>{
            // report the success of our request
            console.log(name+ " Successs put");

            this.db.close();
              
          };
        
        })
        
        

     },


    async introModal()  {
      const top = await modalController.getTop();

      const introModal = await modalController.create({
        component: Amplitude,
        cssClass:"modal-intro",
        componentProps: { 
          tag:"E00A",
          context:"modal"
          },
        swipeToClose: false,
        presentingElement: top
      })

      introModal.addEventListener('didPresent', () => { this.introOpen = true;  });
    
      introModal.onDidDismiss().then(async _ => {
        console.log("DISMISS")
        this.introOpen = false; 
        this.addtoBucket("E00A",'schede_viste_onmap');
        localStorage.setItem("alertmappaletto",1);
      });

    
      return introModal.present();
    },


    savedtag(tags){
        this.visitedTag=tags;
    },
    aggiornavisti(tag){
     const  itemtochchage=this.items.find(it=>it.id==tag);
     const markertochange=this.markers.find(marker=>marker.id==tag)
     if (itemtochchage &&markertochange){
        itemtochchage.status=2;
    
        markertochange.marker.setIcon(this.markerIcon(itemtochchage.status));
      
    
      }
    
      
    },

    buttonsIntro(){
      const buttonArr=[];
      if(this.schede.find(scheda=>scheda.tag=="E00A")){
        const buttonintro={
          text: "Vai all'intro",
          cssClass:'modal-accept-button',
          handler: async() => {
            localStorage.setItem("alertmappaletto",1);
            this.$router.push({ path: "/audio/E00A"});
            
            
          },
        }

        buttonArr.push(buttonintro);
      }else{
        const button= {
            text: "Continua",
            cssClass:'modal-accept-button',
            handler: async() => {
             localStorage.setItem("alertmappaletto",1)
            
            
            },
           
          }

          buttonArr.push(button);

      }
      return buttonArr

    },
    async aletrtMap() {
      const alert = await alertController.create({
       // header: `<img src="./assets/background/map.png" alt="g-maps" style="border-radius: 2px">`,
        message: "Esplora la mappa per raggiungere i punti di interesse" ,
        buttons:this.buttonsIntro()
      });

      await alert.present();
    },

    async aletrtexit() {
      const alert = await alertController.create({
       // header: this.$t('menu.lang.add') ,
        message: this.$t('gps.msgUscita'),
        buttons: [
          {
            text: this.$t('action.continua'),
            cssClass:'modal-accept-button',
            handler: async() => {
              this.open=false;
             // this.map.remove();
             this.stopGps();
              this.clearwatcher();
              this.back();
             
            
            },
          }, {
            text: this.$t('action.cancel'),
            role:"cancel",
            
          },
          
        ],
      });

      await alert.present();
    },

  


    drawMap() {
      this.map = L.map('map', {
        crs: L.CRS.Simple, zoom:this.infomap!=null?parseFloat(this.infomap.minZoom):-2.5,zoomAnimation:false,
        zoomControl:false,
        maxZoom: this.infomap!=null?parseFloat(this.infomap.maxZoom):1,
        minZoom: this.infomap!=null?parseFloat(this.infomap.minZoom):-2.5,
        maxBounds:this.bounds,
        maxBoundsViscosity: 1.0
      }).setView([0, 0])

      L.control.zoom({
       position: 'topright' // Change this to topright, bottomright, bottomleft, or topleft
      }).addTo(this.map);
    

      L.imageOverlay(this.mapImage, this.imageBounds).addTo(this.map);
      this.map.fitBounds(this.bounds);
     
      this.map.invalidateSize();
      this.items.forEach(async item=>{
        if(item.id!="me"){
          const im= await this.getCoverImg(item.img)
            const popupcontent=`<div class="img-container-popup"><img src=${im}></div>
                <div class="card-title museo-style" >${item.description}</div>`
            const marker=L.marker(L.latLng(item.latLng),{ icon:this.markerIcon(item.status)}).addTo(this.map).bindPopup(popupcontent);
            console.log("ICON marker", marker._icon);

            this.markers.push({id:item.id,marker:marker});
        }else{
          this.meMarker=L.marker(L.latLng(item.latLng)).setIcon(this.markerIcon(item.status)).addTo(this.map)
        }
      });

    },


    markerIcon(status) { 
      // Inicializa os ícones
      const urlIcon=[];
      urlIcon[1]='/assets/background/pinlocation.png';
      urlIcon[2]='/assets/background/pinlocation_visto.png';
      urlIcon[3]='/assets/background/hotspot.gif';
      const ColorIcon = L.icon({
        iconUrl:  urlIcon[status],
        shadowUrl: '',
        iconSize: [36, 36],
        iconAnchor: status!=3?[18,36]:[18,18],
        popupAnchor: [1, -34],
        shadowSize: [20, 20]
      
      });
     
      return ColorIcon;
    },
    updateMap() {
      if(this.map){
        this.map.remove();
        this.markers=[];
        this.drawMap();
        
      }

    },

    back(){
      console.log("HISTORU : ",window.history )
     // this.$router.replace({path:"/"});
      this.$router.go(-1);
    },

    calcolaDistanze(){
      if(this.open==true){
      this.schede.forEach(it=>{
          //console.log("calc",this.userCoord, it.coord)
          if( it.coord!=null){
            const distance= this.calculateDistance(this.userCoord, it.coord);
            const range=it.range!=null ? it.range : this.range;
            console.log("distance",it.tag, distance)
            
            if(distance<=range ){
              console.log("intro Open" ,this.introOpen)
              if( !this.visited.includes(it.tag) && !this.introOpen && !this.openingScheda){
                this.openingScheda=true
                this.openscheda(it)
                
              }
            
              //alert("sei vicino al punto");
            }
            
          }
        })
      }
    },

    showPosition(position) {
      if( this.open==false){
        setTimeout(() => {
      this.open=true
     }, 5000);
      }
     
      this.userCoord.latitude= position.coords.latitude,
      this.userCoord.longitude= position.coords.longitude
      const foundme=this.items.find(e=>e.id=="me")
      const mypoint={id: 'me', description: 'ME', latLng: this.coordtopixelPunti(this.userCoord.latitude,  this.userCoord.longitude), status: 3}
      if(foundme){
       console.log(foundme)
        //foundme=mypoint; 
        this.meMarker.setLatLng(this.coordtopixelPunti(this.userCoord.latitude,  this.userCoord.longitude));
      }else{
        this.items.push(mypoint);
      }
      
      console.log("mie coord ",  this.userCoord )
      this.calcolaDistanze();

      // this.getimagepos();
      //this.coordtoCart()
      // this.coordtopixel()
            
    },

    showError(error) {
      switch(error.code) {
          case error.PERMISSION_DENIED:
              alert("Accesso alla posizione negato dall'utente.");
              break;
          case error.POSITION_UNAVAILABLE:
              alert("Informazioni sulla posizione non disponibili.");
              break;
          case error.TIMEOUT:
              alert("Timeout della richiesta di posizione.");
              break;
          case error.UNKNOWN_ERROR:
              alert("Errore sconosciuto.");
              break;
      }
   },

    clearwatcher(){
    console.log("chiudo il watcher");
      navigator.geolocation.clearWatch(this.watcher);
    },
    openscheda(scheda){
      const tag=scheda.tag
      //this.addtoBucket(tag,'schede_viste')
      this.aggiornavisti(tag);
      this.addtoBucket(tag,'schede_viste_onmap')
      const content=scheda.content.find(x => x.lang == this.lang);
      console.log("scheda.type "+ content.type);
      this.salvaPosition();
      this.clearwatcher();
      this.open=false;
      let composePath="";
      if (content.type == "audio") {
        console.log("audio");
        composePath="/audio/" + tag;

      }else if (content.type == "video"){
        console.log("video");
        composePath="/video/" + tag;
    
      }else{
        composePath="/soloImg/" + tag;
      }
      this.$router.push({ path: composePath, replace:false }).finally(() => {
        console.log("finally");
        this.openingScheda = false;
      });

        
    },
    getLocation() {
      if (navigator.geolocation) {
       this.watcher =   navigator.geolocation.watchPosition(this.showPosition, this.showError,{enableHighAccuracy: true, maximumAge: 3000,});
          console.log("dentro, geo ")
      } else {
          alert("Geolocation is not supported by this browser.");
      }
    },

    calculateDistance(coord1, coord2) {
      const earthRadius = 6371; // Raggio medio della Terra in chilometri
      const lat1 = this.degreesToRadians(coord1.latitude);
      const lon1 = this.degreesToRadians(coord1.longitude);
      const lat2 = this.degreesToRadians(coord2.latitude);
      const lon2 = this.degreesToRadians(coord2.longitude);

      const dLat = lat2 - lat1;
      const dLon = lon2 - lon1;

      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(lat1) * Math.cos(lat2) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const distance = earthRadius * c;
      this.distance = distance.toFixed(2)*1000; // Arrotonda la distanza a due cifre decimali
      return  distance.toFixed(2)*1000;
    },
    degreesToRadians(degrees) {
        return degrees * (Math.PI / 180);
    },

    getimagepos(){
      this.mappa=$("#mappa")
      console.log("getmap", this.mappa)
      //this.mappa2=document.getElementById("mappa-cont")
     // const rect =  this.mappa2.getBoundingClientRect();

    
        // Chiamata a getBoundingClientRect() qui
        this.mappa2 = document.getElementById("mappa-cont");
        const rect = this.mappa2.getBoundingClientRect();
        console.log("getmapc", this.mappa2)
       console.log("rect", rect);
   
     

    },

    coordtopixelPunti(lat,lon){

      //milano    
     /* const  lat1= 45.71241717967386
      const  lon1= 8.69531166660485

      const  lat2=  45.18079075895875
      const  lon2= 9.640075829782067*/

      //bergamo
     /* const  lat1= 45.71818229288252
      const  lon1= 9.647315629494203

      const  lat2=45.69061654646927
      const  lon2= 9.681764123048756*/

      const  lat1= this.lat1
      const  lon1= this.lon1

      const  lat2=this.lat2
      const  lon2=this.lon2
     
      
      // bergamo
      /* 1 45.71798229288252, 9.647315629494203*/
      /* 2 45.69059123618014, 9.681764123048756*/

      //1 45.71110808387814, 9.652650465465236
     //2 45.6 9805537615133, 9.676256441490102



        const pixel_x = this.mapwidth * (lon - lon1) / (lon2 - lon1)
        const pixel_y = this.mapheight * (lat - lat1) / (lat2 - lat1)

        console.log( "pixel",pixel_x,  pixel_y);
        return [(this.mapheight-pixel_y),(pixel_x )]

      },

    
    async getCoverImg(name) {
      if (name != null) {
        return new Promise((resolve, reject) => {
          const request = indexedDB.open('mediaStore', global.dbVersion);
          
          request.onsuccess = event => {
            const db = event.target.result;
            const transaction = db.transaction(['media-' + this.lang], 'readwrite');
            const objectStore = transaction.objectStore('media-' + this.lang);
            const test = objectStore.get(name);
            
            test.onsuccess = event => {
              const testget = event.target.result;
              if (testget) {
                const imgSrc = URL.createObjectURL(testget.blob);
                //console.log("img ", imgSrc);
                db.close();
                resolve(imgSrc);
              } else {
                console.log('testget does not exist');
                db.close();
                resolve(""); // or reject with an error message if needed
              }
            };

            test.onerror = () => {
              console.log('test get error');
              db.close();
              reject('Error getting image');
            };
          };

          request.onerror = () => {
            console.log("Error opening database");
            reject('Error opening database');
          };
        });
      } else {
        console.log("name is null");
        return "";
      }
    },
    addtoBucket(ntag, variabile){
      //this.bucket= this.visited;
      if(this.visited==[]){
        this.visited.push(ntag);
        this.visitedSchede={variabile:variabile, ntag:this.visited}

      }else if(!this.visited.includes(ntag)){
        this.visited.push(ntag);
        this.visitedSchede={variabile:variabile, ntag:this.visited}
         // localStorage.setItem(variabile, this.bucket);
      }
       
      console.log("bucket  "+this.visited,+this.visited.length )
      
      
    },
    openviste(){
      this.salvaPosition();
      this.open=false;
      this.$router.push({ name: 'raccolta', params:{ from:"map"}});

     // this.$router.push({ path: "/raccolta", replace:true});
    },

    
  salvaPosition(){
    console.log("salva Position ",this.userCoord)
    localStorage.setItem("userCoord", JSON.stringify(this.userCoord))
  },

  stopGps(){
    try{
      console.log("send not executeJava");
        AndroidObject.executeJavaCodeNotificationMessage(false);//aggiungere parametro  false
      }catch(e){
        console.log(e);
      }
  }
   
 
  }
};



</script>

<style >

ion-content {
  --overflow: hidden;
}
.back-button{
  text-transform: capitalize;
}
.map-icons{
  font-size: 1.6em;
  margin: auto;
}
.history-icon{
  margin-right: 10px;
}
.vertical-center {
  display: flex;
  justify-content: center;
  height: 100%;
}

.center-content {
    display: flex;
    position: absolute;
   
    width:100vw;
    height:100vh;
    flex-direction: column;
    justify-content: center;
}
.leaflet-container{
  background: #ffffff!important;
}
/*.map-container{
  height: 80vh;
}*/
/*.image-map{
  height: 100%;
  object-fit: contain;
    object-position: top;

}*/
#map { 
  width:100%;
  height: 500px;
  z-index: 1;


}
.blur{
  animation: fadeblur 1s;

}
@keyframes fadeblur{
	from { filter: blur(6px); }
	to { filter: blur(0px);}
}
.livello-marker {
    width: auto;
    position: absolute  !important;;
    top: 0;
    left: 0;
}
#punto{
  width: 5px;
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
  background: red;
}
.utils-container{
    position: fixed;
    bottom: 0;
    height: 5vh;
    width: 100%;
    z-index: 5;
    background: white;
    border-top-right-radius: 15px;
    padding: 20px;
    border-top-left-radius: 15px;
}

.buttons{
  width: 100%;
  text-align: center;
}
.collection-button{
  width: 40px;
  height: 28px;
}
.raccolta-button{
  width: 60px;
  height: 60px;
    --background: white;
    --border-radius:15px;

    position: absolute;
    bottom: 20px;
    right: 20px;
}
.leaflet-popup-content{
  margin: 0;
  padding: 15px 14px;
  width: 100% !important;
}

.leaflet-popup-content .img-container-popup {
  width: 55vw;
  top: 12px;
  position: relative;
  /*height: 30vh;*/
 
}

.leaflet-popup-content .card-title{
  font-size: 17px;
  margin-top: 16px;
    position: relative;
}

.leaflet-popup-content .museo-style{
  text-transform: uppercase;
    color: #d26045;
    font-weight: 600;
}

.leaflet-container a.leaflet-popup-close-button {
    font: 23px / 24px Tahoma, Verdana, sans-serif;
    margin: 2px;
  }

.reload-button{
  width: 60px;
    height: 60px;
    --background: white;
    --border-radius:15px;

    position: absolute;
    bottom: 20px;
    left: 20px;
}
.info-coord{
  display: none;
  position: fixed;
  right: 0;
  width: 67%;
  margin-right: 6px;

}

.modal-intro .modal-wrapper{
  width: 89vw;
  height: 95vh;
  border-radius: 1em;
}

.leaflet-popup-content-wrapper{
  width: 62vw;
}




</style>