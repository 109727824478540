export const de= {
    action:{
        accept:"Annehmen",
        postponi:"Später",
        declina:"Ablehnen",
        cancel:"Abbrechen",
        download:"Herunterladen",
        app:"Gehe zur App",
        salta:"Überspringen",
        close:"Close",
        riprova:"Versuch es noch einmal",
        continua:"Fortsetzung"
    },
    onboard:{
        lang:{
            title:"Wählen Sie",
            text:"in welcher Sprache Sie sich die Inhalte am liebsten anhören möchten?"
        },
        accessibility:{
            title:"Zugänglichkeit",
            text:"Für Gehörlose: Untertitel für Multimedia-Inhalte aktivieren",
            alternative:"Im Bereich Barrierefreiheit des Menüs besteht die Möglichkeit, Untertitel von Multimedia-Inhalten für Gehörlose zu aktivieren"
        },
        percorsi:{
            title:"Select ",
            text:"wich path do you want to follow"
        },
        tag:{
            title:"Bringen Sie das Smartphone näher an die SilenceTags heran",
            text:"Nutzen Sie die APP, um auf die vertiefenden Inhalte zuzugreifen"
        },
        qr:{
            title:"Oder ...",
            text:"scannen Sie die QR-Codes, die Sie auf dem Weg finden"
        },


    },
    networkerror:{
		title:"Netzwerkfehler",
        text:"Inhalte können nicht heruntergeladen werden, Sie können die App nicht im Offline-Modus verwenden!",
        action:"Versuche es später"
    },
    storageError:{
		title:"Unzureichender Speicherplatz",
        text:"Es ist nicht möglich, alle Inhalte herunterzuladen, nutzen Sie die App im Online-Modus!",
        action:"Ok"
    },
    update:{
		title:"Update",
        text:"There is new content available, please update it before using the app"
    },
    scarica:{
		title:"Laden Sie den Inhalt herunter",							 
        text:"Die Anwendung wird in Kürze verfügbar sein, dies kann einige Zeit dauern.",
        errorconn:{
            title:"Download Fehler",
            text:"Beim Herunterladen ist ein Problem aufgetreten. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut"
        },
        alertannulla:{
            title:"Download Fehler",
            text:"Beim Herunterladen ist ein Problem aufgetreten. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut"
        }
    },
    capting:[
        "Ich suche dich", 
        "Halt das Telefon still",
        "Noch einen Moment"
    ],
    main:{

        scan:"QR-Code scannen",
        start:"SilenceTag scannen",
        stop:"Stop SilenceTag",
        alertApp:{
            title:"Nicht verfügbar",
            text:"Diese Funktion ist in der App verfügbar",
        },
		timeoutTagTitle:"AUFMERKSAMKEIT",
		timeoutTagText:"SilenceTag wird NICHT erkannt, möglicherweise ist es deaktiviert. Verwenden Sie die Schaltfläche \"QR-Code scannen\"",
		timeoutTagAccept:"Fortsetzen",
        clicktoChangePerc:"Route auswählen"
      
    },
    gps:{
         msgUscita:"Beim Verlassen wird die Geolokalisierung unterbrochen"
    },

    menu:{
        titile:"Einstellungen",
        content:{
            title:"Inhalt aktualisieren"
        },
        accessibility:{
            title:"Zugänglichkeit",
            subtitle:"Für Gehörlose: Untertitel für Multimedia-Inhalte aktivieren",
            supportoVisuale:"Untertitel / Gebärdensprache"
        },
        percorsi:{
            title:"Routen",
            subtitle:"Heruntergeladene Routen",
            change:"Route ändern",
            add:"Fügen Sie eine neue Reiseroute hinzu",
            alert:"Achtung, dieser Vorgang lädt den Inhalt des neuen Pfads herunter",
            select:"Aktive Routen",
        },
        lang:{
            title:"Sprache",
            subtitle:"Sprachen heruntergeladen",
            add:"Neue Sprache hinzufügen",
            select:"Available content",
            alert:"Achtung: Bei diesem Vorgang werden die Inhalte in der gewählten Sprache heruntergeladen",
            it:"Italian",
            en:"Englisch",
            fr:"Französisch",
            de:"Deutsch",
            es:"Spanisch"
        },
        help:"Aiuto",
        copyright:"Copyright",
        credits:"Credits",
        privacy:"Privacy",
        termini:"Nutzungsbedingungen",
        test:{
            title:"Test",
            inizioTest:"Starten Sie den Test",
            stopTest:"Test stoppen",
            testoIstruzioni:"Trennen Sie Ihre Kopfhörer, drehen Sie die Lautstärke auf und führen Sie den Test durch",
            compatibileTest:"Das Gerät ist kompatibel",
            nonCompatibileTest:"Das Gerät ist nicht kompatibel",
        }
        
    },
    raccolta:{
        title:"Sammlung",
        map:"Kehren Sie zur Karte zurück",
        subtitle:"Erleben Sie die Erfahrung noch einmal",
        alerttext:"Es wurde noch kein Inhalt angesehen. \n Kommen Sie am Ende der Tour zurück, um die Inhalte zu überprüfen, die Sie auf dem Weg gefunden haben."
    },
    schede:{
        back:"Back"
    }
}