import { text } from "@fortawesome/fontawesome-svg-core";

export const it = {

    action:{
        accept:"Accetta",
        postponi:"Più tardi",
        declina:"Declina",
        cancel:"Annulla",
        download:"Scarica",
        app:"Apri in App",
        salta:"Salta",
        close:"Chiudi",
        riprova:"Riprova",
        continua:"Continua"
    },
    onboard:{
        lang:{
            title:"Scegli",
            text:"in quale lingua preferisci ascoltare i contenuti?"
        },
        accessibility:{
            title:"Accessibilità",
            text:"Per le persone sorde: attivare i sottotitoli dei contenuti multimediali",
            alternative:"Nella sezione accessibilità del menù, è possibile attivare i sottotitoli dei contenuti multimediali per le persone sorde"
        },
        percorsi:{
            title:"Scegli ",
            text:"il percorso che vuoi seguire"
        },
        tag:{
            title:"Avvicina lo smartphone ai SilenceTag",
            text:"Utilizza l'APP per accedere ai contenuti di approfondimento"
        },
        qr:{
            alternative:"Oppure",
            title:"Scansiona",
            text:"Inquadra i QR Code che troverai lungo il percorso"
        },


    },
    networkerror:{
		title:"Errore di rete",
        text:"Impossibile scaricare i contenuti, non sarà possibile utilizzare l'app in modalità offline!",
        action:"Riprova più tardi"
    },
    storageError:{
		title:"Spazio insufficiente",
        text:"Non è possibile scaricare tutti i contenuti, utilizzare l'app in modalità online!",
        action:"Ok"
    },
    update:{
		title:"Aggiornamento",
        text:"Sono disponibili nuovi contenuti, aggiornali prima di usare l'app"
    },
    scarica:{
		title:"Scaricamento dei contenuti",
        text:"A breve l'applicazione sarà disponibile, l'operazione può richiedere qualche minuto.",
        errorconn:{
            title:"Errore nel download",
            text:"Si è verificato un problema nello scaricamento, controlla la connessione e riprova"
        },
        alertannulla:{
            title:"Attenzione",
            text:"I contenuti non sono stati scaricati, l'applicazione può funzionare solo online. Riprova più tardi a scaricare per una migliore esperienza"
        }
    },
    capting:[
        "Cerco dove sei", 
        "Tieni fermo il telefono",
        "Ancora un momento..."
    ],
    main:{

        scan:"Scansiona QR Code",
        start:"Scansiona Silence Tag",
        stop:"Stop SilenceTag",
		alertApp:{
            title:"funzione non disponibile",
            text:"Questa funzione è disponibile solo in app",
        },
		timeoutTagTitle:"ATTENZIONE",
		timeoutTagText:"SilenceTag NON rilevato, potrebbe essere spento, utilizza il pulsante \"Scansiona QR Code\"",
		timeoutTagAccept:"Prosegui",
        clicktoChangePerc:"Clicca per attivare"

      
    },
    gps:{
        msgUscita:"Uscendo la geolocalizzazione verrà interrotta"
    },

    menu:{
        title:"Impostazioni",
        content:{
            title:"Aggiorna contenuti"
        },
        accessibility:{
            title:"Accessibilità",
            subtitle:"Per le persone sorde: attivare i sottotitoli dei contenuti multimediali",
            supportoVisuale:"Sottotitoli / LIS"
        },
        percorsi:{
            title:"Percorsi",
            subtitle:"Percorsi scaricati",
            change:"Cambia percorso",
            add:"Aggiungi percorso",
            alert:"Attenzione questa operazione scaricherà i contenuti del nuovo percorso",
            select:"Percorsi attivi",
        },
        lang:{
            title:"Lingue",
            subtitle:"Lingue scaricate",
            add:"Aggiungi nuova lingua",
            select:"Contenuti disponibili",
            alert:"Attenzione questa operazione scaricherà i contenuti nella lingua scelta",
            it:"Italiano",
            en:"Inglese",
            fr:"Francese",
            de:"Tedesco",
            es:"Spagnolo"
        },
        help:"Aiuto",
        copyright:"Copyright e Privacy",
        privacy:"Privacy",
        credits:"Crediti",
        termini:"Termini di servizio",
        test:{
            title:"Test",
            inizioTest:"Inizio TEST",
            stopTest:"Stop TEST",
            testoIstruzioni:"scollega le cuffie, alza il volume e esegui il test",
            compatibileTest:"Il dispositivo è compatibile",
            nonCompatibileTest:"Il dispositivo non è compatibile",
        }

    },
    raccolta:{
        title:"Raccolta",
        map:"Torna alla mappa",
        subtitle:"Rivivi l'esperienza",
        alerttext:"Non hai ancora aperto le schede. \n Alla fine del tour potrai rivedere qui le schede trovate lungo il percorso."
    },
    schede:{
        back:"Indietro"
    }
   

}